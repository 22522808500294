<template>
  <div class="common-wrapper">
    <div class="aside">
      <header class="aside-header">
        <bars-outlined />
        <div>产品类目树</div>
      </header>
      <div class="aside-content">
        <div class="aside-content-list">
          <a-tree v-if="treeData.length" show-icon :tree-data="treeData" default-expand-all :selectedKeys="selectedKeys"
            :fieldNames="{ key: 'id', children: 'children', title: 'name' }" @select="selectTreeNode">
            <template #icon="{ dataRef, expanded }">
              <auth-image v-if="!dataRef.isCatalogue" :src="dataRef.image" size="18px" />
              <img v-else-if="expanded" src="../../../../assets/folder-open.png" />
              <img v-else src="../../../../assets/folder.png" />
            </template>
            <template #title="{ dataRef }">
              <a-tooltip placement="topLeft" :title="dataRef.name">
                <div class="aside-content-item">
                  <div class="aside-content-item-title">{{ dataRef.name }}</div>
                  <div class="btns">
                    <form-outlined v-if="dataRef.isCatalogue" @click="editDirectory(dataRef)" />
                    <delete-outlined v-if="!dataRef.children.length" @click="deleteTreeNode(dataRef)" />
                  </div>
                </div>
              </a-tooltip>
            </template>
          </a-tree>
        </div>
      </div>
      <div class="op-btns">
        <a-button size="small" @click="addDirectory">添加目录</a-button>
        <a-button size="small" @click="addCategory">添加品类</a-button>
      </div>
    </div>
    <div class="content">
      <a-empty v-if="!selectedKeys.length" :image="Empty.PRESENTED_IMAGE_SIMPLE" class="empty-status" />
      <template v-else>
        <basic-card title="基础信息" :titleHeight="'46px'">
          <template #right>
            <a-button type="primary" @click="editCategory">编辑</a-button>
          </template>
          <a-descriptions class="descriptions">
            <a-descriptions-item label="品类归属">{{ baseInfo.ascription }}</a-descriptions-item>
            <a-descriptions-item label="品类编码">{{ baseInfo.code }}</a-descriptions-item>
            <a-descriptions-item label="品类名称">{{ baseInfo.name }}</a-descriptions-item>
            <a-descriptions-item label="品类图片">
              <auth-image :src="baseInfo.image" size="64px" />
            </a-descriptions-item>
            <a-descriptions-item label="品类描述" :span="2">{{ baseInfo.description }}</a-descriptions-item>
          </a-descriptions>
        </basic-card>
        <basic-card title="功能模板" class="template" :titleHeight="'46px'">
          <category-function :id="selectedKeys[0]" />
        </basic-card>
      </template>
    </div>
    <a-modal v-model:visible="visible" :title="`${isEditing ? '修改' : '添加'}目录`" @ok="ok" @cancel="cancel"
      :confirm-loading="loading">
      <a-form ref="formRef" :model="formState" :rules="rulesRef" :label-col="{ span: 4 }">
        <a-form-item name="name" label="目录名称">
          <a-input v-model:value="formState.name" placeholder="请输入"></a-input>
        </a-form-item>
        <a-form-item name="parentId" label="上级目录">
          <a-tree-select v-model:value="formState.parentId" show-search style="width: 100%" placeholder="请选择" allow-clear
            tree-default-expand-all :tree-data="parentDirList" tree-node-filter-prop="name" :field-names="{
              children: 'children',
              label: 'name',
              value: 'id',
            }">
          </a-tree-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal :visible="visible2" :title="`${isEditing2 ? '修改' : '添加'}品类`" @ok="ok2" @cancel="cancel2"
      :confirm-loading="loading2">
      <a-form ref="formRef2" :model="formState2" :rules="rulesRef2" :label-col="{ span: 4 }">
        <a-form-item name="name" label="品类名称">
          <a-input v-model:value="formState2.name" placeholder="请输入"></a-input>
        </a-form-item>
        <a-form-item name="parentId" label="所属目录">
          <a-tree-select v-model:value="formState2.parentId" show-search style="width: 100%" placeholder="请选择" allow-clear
            tree-default-expand-all :tree-data="parentDirList[0].children" tree-node-filter-prop="name" :field-names="{
              children: 'children',
              label: 'name',
              value: 'id',
            }">
          </a-tree-select>
        </a-form-item>
        <a-form-item name="code" label="品类编码">
          <a-input v-model:value="formState2.code" placeholder="请输入"></a-input>
        </a-form-item>
        <a-form-item name="image" label="品类图片">
          <upload-image v-model:value="formState2.image" @change="toValidate(['image'])" />
          <!-- <img v-else src="../../../../assets/default_image.png" width="100" /> -->
        </a-form-item>
        <a-form-item name="description" label="品类描述">
          <a-textarea v-model:value="formState2.description" placeholder="请输入"></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<!-- eslint-disable no-unused-vars -->

<script setup>
import { BarsOutlined } from "@ant-design/icons-vue";
import { ref, reactive, onMounted, nextTick } from 'vue';
import categoryFunction from './components/categoryFunction.vue';
import { apiCategoryManageDir, apiCategoryDetail } from "@/api/IoT/productCenter.js";
import { message, Modal, Empty } from 'ant-design-vue';
import UploadImage from '@/components/basic/upload/uploadImage.vue';
import authImage from '@/components/basic/authImage/index.vue';

const treeData = ref([]);
const parentDirList = ref([])
const selectedKeys = ref([])


const validateDuplicate = async ({ field }, value) => {
  let m = {
    [field]: value,
    id: formState.id
  }
  if (value) {
    let { result } = await apiCategoryManageDir.dirNameUnique(m)
    if (!result) {

      return Promise.reject();
    }
  }
  return Promise.resolve();
}
const isEditing = ref(false)
const visible = ref(false)
const loading = ref(false)
const formRef = ref()
const formState = reactive({
  id: undefined,
  name: undefined,
  parentId: undefined,
})
const rulesRef = reactive({
  name:
    [{ required: true, message: '请输入目录名称' },
    {
      validator: validateDuplicate, message: '目录名称已存在，请输入目录名称'
    }],
});

const validateDuplicate2 = async ({ field }, value) => {
  let m = {
    [field]: value,
    id: formState2.id
  }
  if (value) {
    let { result } = await apiCategoryManageDir.categoryCodeUnique(m)
    if (!result) {
      return Promise.reject();
    }
  }
  return Promise.resolve();
}
const isEditing2 = ref(false)
const visible2 = ref(false)
const loading2 = ref(false)
const formRef2 = ref()
const formState2 = reactive({
  name: undefined,
  parentId: undefined,
  code: undefined,
  image: '',
  description: undefined,
})
const baseInfo = reactive({
  name: undefined,
  parentId: undefined,
  code: undefined,
  image: '',
  description: undefined,
})
const rulesRef2 = reactive({
  name: [{ required: true, message: '请输入目录名称' }],
  parentId: [{ required: true, message: '请选择所属目录' }],
  code: [
    { required: true, message: '请输入品类编码' },
    {
      pattern: /^[a-zA-Z][a-zA-Z0-9]*$/,
      message: '品类编码只允许字母、数字组成，首位只能为字母'
    },
    {
      validator: validateDuplicate2, message: '品类编码已存在，请输入其他编码'
    }
  ],
});


onMounted(() => {
  initTree()
})

const initTree = () => {
  apiCategoryManageDir.list().then(({ result }) => {
    treeData.value = result
    if (!selectedKeys.value.length) {
      findFirstCategory(result[0].children)
    }
  })
  apiCategoryManageDir.list(false).then(({ result }) => {
    parentDirList.value = result
  })
}

const findFirstCategory = (arr) => {
  for (let idx = 0; idx < arr.length; idx++) {
    setFirstSelectedValue(arr[idx])
  }
}

const setFirstSelectedValue = (e) => {
  if (!selectedKeys.value.length) {
    if (!e.isCatalogue) {
      selectTreeNode([e.id], { node: { dataRef: e } })
    } else {
      findFirstCategory(e.children)
    }
  }
}

const selectTreeNode = (keys, { node }) => {
  if (!node.dataRef?.isCatalogue && keys.length) {
    selectedKeys.value = keys
    apiCategoryDetail.detail(keys[0]).then(({ result }) => {
      for (const key in result) {
        baseInfo[key] = result[key]
      }
    })
  }
}

const addDirectory = () => {
  visible.value = true
  isEditing.value = false
}
const editDirectory = (row) => {
  if (row.isCatalogue) {
    visible.value = true;
    isEditing.value = true;
    nextTick(() => {
      formState.name = row.name;
      formState.parentId = row.parentId;
      formState.id = row.id;
    })
  }
  //  else {
  //   visible2.value = true;
  //   isEditing2.value = true;
  //   formState2.id = row.id;
  //   formState2.name = row.name;
  //   formState2.parentId = row.parentId;
  //   formState2.code = row.code;
  //   formState2.image = row.image;
  //   formState2.description = row.description;
  // }
}
const deleteTreeNode = (row) => {
  Modal.confirm({
    title: '提醒',
    content: '请确认是否删除？',
    onOk() {
      return new Promise((resolve) => {
        let func = row.isCatalogue ? apiCategoryManageDir : apiCategoryDetail
        func.delete(row.id).then(() => {
          message.success('删除成功');
          selectedKeys.value = []
          initTree()
        }).finally(() => {
          resolve()
        })
      })
    }
  });
}
const ok = () => {
  formRef.value.validateFields().then(() => {
    loading.value = true
    let m = {
      id: formState.id,
      name: formState.name,
      parentId: formState.parentId || 0
    }
    let func = isEditing.value ? 'edit' : 'add'
    apiCategoryManageDir[func](m).then(() => {
      message.success(`${isEditing.value ? '编辑' : '添加'}成功`)
      visible.value = false
      formRef.value.resetFields();
      initTree()
    })
  }).finally(() => {
    loading.value = false
  });
}
const cancel = () => {
  visible.value = false
  formRef.value.resetFields()
  delete formState.id
}



// 品类
const addCategory = () => {
  visible2.value = true
  isEditing2.value = false
}
const editCategory = () => {
  visible2.value = true
  isEditing2.value = true
  nextTick(() => {
    formState2.id = baseInfo.id;
    formState2.parentId = baseInfo.parentId;
    formState2.code = baseInfo.code;
    formState2.name = baseInfo.name;
    formState2.image = baseInfo.image;
    formState2.description = baseInfo.description;
  })

}
const toValidate = (keys) => {
  formRef2.value.validateFields(keys)
}

const ok2 = () => {
  formRef2.value.validateFields().then(() => {
    loading2.value = true
    let m = {
      id: formState2.id,
      name: formState2.name,
      parentId: formState2.parentId,
      code: formState2.code,
      image: formState2.image,
      description: formState2.description,
    }
    let func = isEditing2.value ? 'edit' : 'add'
    apiCategoryDetail[func](m).then(({ result }) => {
      message.success(`${isEditing.value ? '编辑' : '添加'}成功`)
      visible2.value = false
      formRef2.value.resetFields();
      initTree()
      selectedKeys.value = [result]
      selectTreeNode([result], { node: { dataRef: { id: result } } })
    })
  }).finally(() => {
    loading2.value = false
  });
}
const cancel2 = () => {
  visible2.value = false
  formRef2.value.resetFields()
  delete formState2.id
}


</script>
<style lang='less' scoped>
.common-wrapper {
  position: relative;
  background: none;
  padding: 0;
  height: 100%;
}

.aside {
  position: absolute;
  left: 0;
  top: 0;
  width: 240px;
  height: 100%;
  background-color: #fff;

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 46px;
    padding: 0px 16px;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  &-content {
    padding: 11px 8px;
    height: calc(100% - 100px);
    overflow: auto;
    &-all {
      padding: 0 4px;
      height: 24px;
      font-size: 14px;
      font-weight: bold;
    }


    &-item {
      display: flex;
      justify-content: space-between;
      flex: 1;


      &-title {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .btns {
        display: none;
      }
    }
  }

  .op-btns {
    position: absolute;
    bottom: 12px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 8px;
  }



  :deep .ant-tree-treenode {
    width: 100% !important;

    .ant-tree-node-content-wrapper {
      display: flex;
      flex: 1;

      .ant-tree-title {
        flex: 1;
        width: 0;
        overflow: hidden;
      }

      &:hover .btns {
        display: block;
      }
    }

    .anticon {
      width: 14px;
      margin-left: 8px;
    }
  }
}

.content {
  position: relative;
  margin-left: 248px;
  display: flex;
  
  flex-direction: column;
  padding: 0 20px;
  height: 100%;
  background-color: #fff;

  .descriptions {
    padding: 17px 20px 0 20px;
    border: 1px solid #E6E8EB;
    border-radius: 4px;
  }

  .template {
    flex: 1;
  }
}

:deep .ant-tabs-content {
  height: 100%;
}

.empty-status {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
<template>
  <div class="category-function">
    <a-tabs v-model:activeTab="activeTab" size="small" type="card">
      <a-tab-pane v-for="(tab) in tabs" :key="tab.value" :tab="tab.label">
        <function-template :tab="tab" :id="id" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script setup>
import { ref, onMounted, provide, defineProps } from 'vue'
import { apiCategoryProductParams, apiCategoryDeviceParams, apiCategoryDeviceAttribute, apiCategoryDeviceEvent, apiCategoryDeviceService } from "@/api/IoT/productCenter.js";

import { apiDict } from '@/api/dict';
import functionTemplate from './functionTemplate.vue';

defineProps({
  id: String
})

const activeTab = ref('01')
const functionType = ref([])
const dataType = ref([])
const unit = ref([])
const rwType = ref([])
const tabs = ref([])
const tabsFunc = [apiCategoryProductParams, apiCategoryDeviceParams, apiCategoryDeviceAttribute, apiCategoryDeviceEvent, apiCategoryDeviceService]
provide('provideParams', { functionType, dataType, unit, rwType })

onMounted(() => {
  apiDict.selective('PRODUCT_FUNCTION_TYPE').then(({ result }) => {
    functionType.value = result
  })
  apiDict.selective('PARAM_DATA_TYPE').then(({ result }) => {
    dataType.value = result
  })
  apiDict.selective('UNIT').then(({ result }) => {
    unit.value = result
  })
  apiDict.selective('RW_TYPE').then(({ result }) => {
    rwType.value = result
  })
  apiDict.selective('PRODUCT_FUNCTION_CATEGORY').then(({ result }) => {
    tabs.value = result.map((e, idx) => {
      return {
        ...e,
        func: tabsFunc[idx],
      }
    })
  })
})
</script>
<style lang='less' scoped>
.common-wrapper {
  position: relative;
  padding-top: 0
}

.category-function {
  position: relative;
  height: 100%;
}

:deep .ant-tabs {
  height: 100%;

  .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs-tab {
    margin: 0 !important;
    border-radius: 0 !important;
    border-left: none !important;

    &:first-child {
      border-left: 1px solid #f0f0f0 !important;
    }

    &-active {
      background-color: @primary-color !important;

      .ant-tabs-tab-btn {
        color: #fff !important;
      }
    }

  }
}

:deep .function-template {
  position: relative;
  height: 100%;

  .table-header {
    position: absolute;
    left: 470px;
    top: -50px;
    right: 0;

    .category-search {
      width: 248px;
    }

    .search-container {

      .container_wrap {
        width: 212px;
      }
    }
  }

  .template-table {
    padding-top: 16px;
  }
}
</style>
<template>
    <div class="upload-image" @mouseenter="isOutside = false" @mouseleave="isOutside = true">
        <a-upload class="upload-image" list-type="picture-card" :show-upload-list="false" :before-upload="beforeUpload"
            :customRequest="customRequest" :disabled="disabled">
            <div class="upload-image-item" v-if="value" @click.prevent.stop="">
                <div class="upload-image-item-info" >
                    <auth-image :src="value" height="auto" v-model:imgSrc="imgSrc" />
                    <div class="upload-image-item-op">
                        <EyeOutlined class="upload-image-item-op-preview" @click="previewVisible = true" />
                        <CloseCircleOutlined v-if="!disabled" class="upload-image-item-op-delete" @click="onDelete" />
                    </div>
                </div>
            </div>

            <div v-else>
                <LoadingOutlined v-if="loading" />
                <PlusOutlined v-else />
                <div class="ant-upload-text">上传</div>
            </div>
        </a-upload>
        <a-image :width="200" :style="{ display: 'none' }" :preview="{
            visible: previewVisible,
            onVisibleChange: changePreview
        }" :src="imgSrc" />
    </div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue';
import { apiFileUpload } from '@/api/common.js';
import { message } from "ant-design-vue";
import authImage from '../authImage/index.vue';

defineProps({
    value: {
        type: String
    },
    disabled: {
        type: String,
        disabled: false
    }
})

const loading = ref(false)
const previewVisible = ref(false)
const imgSrc = ref('')
const isOutside = ref(true)
const emit = defineEmits(["update:value",'change']);

const customRequest = async (file) => {
    let formData = new FormData();
    formData.append("file", file.file);
    loading.value = true;
    try {
        const { result } = await apiFileUpload.image(formData);
        loading.value = false;
        emit("update:value", result);
        emit("change", result);
    } catch (error) {
        loading.value = false;
        console.log(error);
    }
};

const beforeUpload = (file) => {
    const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        message.error("仅支持png、jpg格式文件!");
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
        message.error("图片大小不能超过10MB!");
    }

    return isJpgOrPng && isLt10M;
};

const changePreview = (value)=>{
    previewVisible.value = value
}
const onDelete = () => {
    emit("update:value", "");
    emit("change", "");
}

</script>

<style lang="less" scoped>
.upload-image {
    width: 100px;
    height: 100px;
    cursor: pointer !important;
}

.upload-image-item {
    position: relative;
    cursor: pointer;

    &-info {
        position: relative;
        .previewPhoto{
            position: fixed;
            left: 700px;
            top: 50%;
            transform: translate3d(0,-50%,0);
            z-index: 100;
        }
    }

    &-op {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        white-space: nowrap;
        transform: translate(-50%, -50%);
        // opacity: 0;
        transition: all .3s;
    }

    .anticon {
        color: #fff;
        margin: 0 4px;
    }

    &-info:before {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: #00000080;
        opacity: 0;
        transition: all .3s;
        content: " ";

    }

    &:hover &-info::before {
        opacity: 1;

    }

    &:hover &-op {
        display: block;

    }

}

:deep .ant-upload.ant-upload-select-picture-card.ant-upload-disabled {
    border-color: transparent !important;
}
:deep .ant-upload.ant-upload-disabled {
    cursor: default !important;
}
</style>
<template>
    <div class='function-template'>
        <div class="table-header">
            <categorySearch :source="filterSource" class="searchBlock" @change="initTableData" />
            <a-button type="primary" @click="handleAdd">添加功能</a-button>
        </div>
        <basic-table ref="tableRef" class="template-table" v-bind="tableConfig" @page-change="pageChange">
            <template #slotUnit="{ row }">
                <span>{{ row.unit || '/' }}</span>
            </template>
        </basic-table>
        <a-modal :visible="visible" title="添加标准功能" @ok="ok" @cancel="cancel" width="900px">
            <categorySearch :source="filterSource" class="searchBlock" @change="initTableData2" />
            <basic-table ref="tableRef2" v-bind="tableConfig2" @page-change="pageChange2" />
        </a-modal>
        <a-modal :visible="visible2" title="修改功能名称" @ok="ok2" @cancel="cancel2">
            <a-form ref="formRef2" :model="formState2" :label-col="{ span: 4 }">
                <a-form-item name="name" label="功能名称" :rules="[{ required: true, message: '请输入功能名称' }]">
                    <a-input v-model:value="formState2.name"></a-input>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script setup>
import categorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from '@/components/basic/table'
import { computed, ref, onMounted, defineProps, reactive, watch, nextTick } from 'vue'
import { message, Modal } from 'ant-design-vue';
import { apiCategoryOpFunction } from "@/api/IoT/productCenter.js";

onMounted(() => {
    initTableData()
})

const props = defineProps({
    tab: Object,
    id: {
        type: String,
        require: true
    }
})

watch(() => props.id, () => {
    if (props.id) {
        initTableData()
    }
})

const filterSource = [
    {
        label: '功能名称',
        type: 'input',
        bindKey: 'functionName',
        placeholder: '请输入'
    },
    {
        label: '功能标识符',
        type: 'input',
        bindKey: 'identifier',
        placeholder: '请输入'
    },
]
const visible = ref(false)
const visible2 = ref(false)
const formState2 = reactive({
    name: undefined
})
const formRef2 = ref()

const tableConfig = computed(() => {
    let tableConfig = {
        showIndex: false,
        showSelect: false,
        pagination: true,
        isPageBottom: true,
        remote: false, //!!!!!!!!!!!!!!!!!!!!!!!
        columns: [],
        scroll: {
            y: "calc(100vh - 540px)",
        },
        action: {
            title: "操作",
            width: 100,
            fixed: "right",
            items: [
                {
                    icon: "FormOutlined",
                    iconTip: '编辑',
                    type: "icon",
                    visibleFn: (row) => row.functionType !== '01' && row.functionType !== '02',
                    onClick: (row) => {
                        handleEdit(row);
                    },
                },
                {
                    icon: "DeleteOutlined",
                    iconTip: '删除',
                    type: "icon",
                    visibleFn: (row) => row.functionType !== '01',
                    onClick: (row) => {
                        handleDelete(row.id);
                    },
                },
            ],
        },
    }
    if (props.tab.value === '01') {
        tableConfig.columns = [
            {
                title: "标识符",
                type: "text",
                key: "identifier",
            },
            {
                title: "名称",
                type: "text",
                key: "functionName",
            },
            {
                title: "类型",
                type: "text",
                key: "functionTypeContent",
            },
            {
                title: "数据类型",
                type: "text",
                key: "dataTypeContent",
            },
            {
                title: "值约束",
                type: "text",
                key: "valueDescriptionContent",
            },
            {
                title: "单位",
                type: "customize",
                slotName: "slotUnit",
            },
        ]
    } else if (props.tab.value === '02' || props.tab.value === '03') {
        tableConfig.columns = [
            {
                title: "标识符",
                type: "text",
                key: "identifier",
            },
            {
                title: "名称",
                type: "text",
                key: "functionName",
            },
            {
                title: "类型",
                type: "text",
                key: "functionTypeContent",
            },
            {
                title: "数据类型",
                type: "text",
                key: "dataTypeContent",
            },
            {
                title: "值约束",
                type: "text",
                key: "valueDescriptionContent",
            },
            {
                title: "读写",
                type: "text",
                key: "rwContent",
            },
            {
                title: "单位",
                type: "customize",
                slotName: "slotUnit",
            },
        ]
    } else {
        tableConfig.columns = [
            {
                title: "标识符",
                type: "text",
                key: "identifier",
                width: 150
            },
            {
                title: "名称",
                type: "text",
                key: "functionName",
                width: 150
            },
            {
                title: "类型",
                type: "text",
                key: "functionTypeContent",
                width: 150
            },
            {
                title: "事件参数",
                type: "text",
                key: "eventParameterContent",
                ellipsis: true
            },
            {
                title: "响应参数",
                type: "text",
                key: "responseParameterContent",
                ellipsis: true
            }
        ]
    }
    return tableConfig
})
const tableRef = ref();
const tableRef2 = ref();


const tableConfig2 = reactive({
    showIndex: false,
    showSelect: true,
    pagination: true,
    isPageBottom: false,
    remote: false,
    loading: false,
    columns: [
        {
            title: "标识符",
            type: "text",
            key: "identifier",
            width: 120,
        },
        {
            title: "名称",
            type: "text",
            key: "functionName",
            width: 120,
        },
        {
            title: "功能类别",
            type: "text",
            key: "functionTypeContent",
            width: 120,
        },
        {
            title: "数据类型",
            type: "text",
            key: "dataTypeContent",
            width: 120,
        },
        {
            title: "值描述",
            type: "text",
            key: "valueDescriptionContent",
        }
    ]
});

const initTableData = (searchItems = {}) => {
    props.tab.func.list({
        pageNo: tableRef.value.paginationProps.current,
        pageSize: tableRef.value.paginationProps.pageSize,
        categoryBizId: props.id,
        ...searchItems
    }).then(({ result }) => {
        let data = result.records.map(e => {
            return {
                ...e,
                functionParameter: e.eventParameter
            }
        })
        tableRef.value.dataSourceRef = data || [];
        tableRef.value.paginationProps.total = result.total;
    })
};
const initTableData2 = (searchItems = {}) => {
    props.tab.func.addList({
        pageNo: tableRef2.value.paginationProps.current,
        pageSize: tableRef2.value.paginationProps.pageSize,
        categoryBizId: props.id,
        ...searchItems
    }).then(({ result }) => {
        tableRef2.value.dataSourceRef = result.records || [];
        tableRef2.value.paginationProps.total = result.total;
    })
};
const pageChange = (pageIndex) => {
    tableRef.value.paginationProps.current = pageIndex
    initTableData()
}
const pageChange2 = (pageIndex) => {
    tableRef2.value.paginationProps.current = pageIndex
    initTableData2()
}

const handleAdd = () => {
    visible.value = true
    nextTick(() => {
        initTableData2()
    })
}

const handleEdit = (row) => {
    visible2.value = true;
    nextTick(() => {
        formState2.name = row.functionName
        formState2.id = row.id
    })
}

const handleDelete = (id) => {
    Modal.confirm({
        title: '提醒',
        content: '请确认是否删除？',
        onOk() {
            return new Promise((resolve) => {
                let m = {
                    featureIds: [id],
                    categoryBizId: props.id,
                    functionCategory: props.tab.value
                }
                apiCategoryOpFunction.delete(m).then(() => {
                    message.success('删除成功');
                    initTableData()
                }).finally(() => {
                    resolve()
                })
            })
        }
    });
}

const ok = () => {
    let m = {
        featureIds: tableRef2.value.selectedData.keys,
        categoryBizId: props.id,
        functionCategory: props.tab.value
    }
    apiCategoryOpFunction.add(m).then(() => {
        message.success('添加成功')
        visible.value = false
        tableRef2.value.clearSelected()
        initTableData()
    })


}
const cancel = () => {
    visible.value = false
    tableRef2.value.clearSelected()
}
const ok2 = () => {
    let m = {
        functionId: formState2.id,
        categoryBizId: props.id,
        functionCategory: props.tab.value,
        functionName: formState2.name
    }
    props.tab.func.edit(m).then(() => {
        message.success('修改成功')
        visible2.value = false
        formRef2.value.resetFields();
        initTableData()
    })

}
const cancel2 = () => {
    visible2.value = false
    formRef2.value.resetFields();
}



</script>
<style lang='less' scoped>
.table-header {
    display: flex;
    justify-content: space-between;
}
</style>